import { request } from '@/api/request';

//商户登录密码
export const merchantAdminLogin = data => {
	return request('post','/api/Login/MerchantAdmin/login',data);
}

//商户登录验证码登录
export const smsLogin = data => {
	return request('post','/api/Login/MerchantAdmin/smsLogin',data);
}

//短信发送
export const sendSms = data => {
	return request('get','/api/ali/sendSms',data);
}

//概览
export const dataindex = data => {
	return request('get','/api/data/index',data);
}

//商户用户列表
export const MerchantUserlist = data => {
	return request('get','/api/Account/MerchantUser/list',data);
}

//商户用户详情
export const MerchantUserdetail = data => {
	return request('get','/api/Account/MerchantUser/detail',data);
}

//商户用户消费信息
export const consumptionInfo = data => {
	return request('get','/api/Account/MerchantUser/consumptionInfo',data);
}

//商户用户充值
export const MerchantUserrecharge = data => {
	return request('post','/api/Account/MerchantUser/recharge',data);
}

//商户用户资金明细
export const accountCapitalLog = data => {
	return request('get','/api/Account/MerchantUser/accountCapitalLog',data);
}

//商户用户保存标签
export const saveTags = data => {
	return request('post','/api/Account/MerchantUser/saveTags',data);
}

//商户等级列表
export const levelList = data => {
	return request('get','/api/Account/MerchantUser/levelList',data);
}

//商户等级详情
export const levelDetail = data => {
	return request('get','/api/Account/MerchantUser/levelDetail',data);
}

//商户等级编辑
export const levelModify = data => {
	return request('post','/api/Account/MerchantUser/levelModify',data);
}

//商户门店列表
export const MerchantShoplist = data => {
	return request('get','/api/Account/MerchantShop/list',data);
}

//商户门店修改状态
export const MerchantShopstatus = data => {
	return request('post','/api/Account/MerchantShop/status',data);
}

//商户门店修改管理员账号
export const saveAdminAccount = data => {
	return request('post','/api/Account/MerchantShop/saveAdminAccount',data);
}

//商户门店详情
export const MerchantShopdetail = data => {
	return request('get','/api/Account/MerchantShop/detail',data);
}

//商户门店修改
export const MerchantShopmodify = data => {
	return request('post','/api/Account/MerchantShop/modify',data);
}

//获取系统配置
export const getConfig = data => {
	return request('get','/api/Settings/system/getConfig',data);
}

export const getShareImage = data => {
	return request('post','/api/wx/getShareImage',data);
}