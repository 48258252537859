import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '众康联美康商户管理系统',
    redirect: '/login',
  },
  {
    path: '/login',
    // name: '登录',
    meta: { title: "众康联美康商户管理系统", requireAuth: true },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/home',
    name: '概览',
    icon: "icon-shouye",
    meta: { title: "概览", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/home",
        // name: "首页",
        icon: "icon-shouye",
        meta: { title: "概览", requireAuth: true },
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/manage',
    name: '管理员',
    icon: "icon-guanliyuan1",
    meta: { title: "管理员", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/manage/adminList",
        name: "管理员列表",
        icon: "icon-guanliyuan",
        meta: { title: "管理员列表", requireAuth: true },
        component: () => import('@/views/manage/adminList/adminList.vue')
      },
      {
        path: "/manage/auth",
        name: "角色管理",
        icon: "icon-quanxianguanli",
        meta: { title: "角色管理", requireAuth: true },
        component: () => import('@/views/manage/auth/auth.vue')
      },
      {
        path: "/manage/routeList",
        name: "路由列表",
        icon: "icon-guanliyuan",
        meta: { title: "路由列表", requireAuth: true },
        component: () => import('@/views/manage/routeList/routeList.vue')
      }
    ]
  },
  {
    path: '/user',
    name: '用户管理',
    icon: "icon-kehu",
    meta: { title: "用户管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/user/index",
        name: "用户列表",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "用户列表", requireAuth: true },
        component: () => import('@/views/user/index.vue'),
      },
      {
        path: "/user/levelIndex",
        name: "等级设置",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "等级设置", requireAuth: true },
        component: () => import('@/views/user/levelIndex.vue'),
      },
      {
        path: "/user/userDetails",
        name: "用户详情",
        icon: "icon-shouye",
        meta: { title: "用户详情", requireAuth: true },
        component: () => import('@/views/user/userDetails/index.vue'),
      },
      {
        path: "/user/levelDetalis",
        name: "等级详情",
        icon: "icon-shouye",
        meta: { title: "等级详情", requireAuth: true },
        component: () => import('@/views/user/userDetails/levelDetalis.vue'),
      }
    ]
  },
  {
    path: '/storeManagement',
    name: '门店管理',
    icon: "icon-shezhi",
    meta: { title: "门店管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/storeManagement/storeList",
        name: "门店列表",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "门店列表", requireAuth: true },
        component: () => import('@/views/storeManagement/storeList/storeList.vue')
      },
      {
        path: "/storeManagement/editStore",
        name: "门店编辑",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "门店编辑", requireAuth: true },
        component: () => import('@/views/storeManagement/storeList/editStore.vue')
      },
    ]
  },
  {
    path: '/commodity',
    name: '商品管理',
    icon: "icon-shezhi",
    meta: { title: "商品管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/commodity/self",
        name: "平台供货库",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "平台供货库", requireAuth: true },
        component: () => import('@/views/commodity/selfIndex.vue')
      },
      {
        path: "/commodity/selfDetails",
        name: "查看",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "查看", requireAuth: true },
        component: () => import('@/views/commodity/selfDetails/selfDetails.vue') 
      },
      {
        path: "/commodity/cattle",
        name: "商户自营库",
        icon: "icon-shangpinduibi",
        meta: { title: "商户自营库", requireAuth: true },
        component: () => import('@/views/commodity/cattleIndex.vue')
      },
      {
        path: "/commodity/addCommodity",
        name: "新增编辑商品",
        icon: "icon-shouye",
        meta: { title: "新增编辑商品", requireAuth: true },
        component: () => import('@/views/commodity/addCommodity/index.vue')
      },
      {
        path: "/commodity/auditList",
        name: "审核列表",
        icon: "icon-shouye",
        meta: { title: "审核列表", requireAuth: true },
        component: () => import('@/views/commodity/auditList.vue')
      }
    ]
  },
  {
    path: '/order',
    name: '订单管理',
    icon: "icon-shezhi",
    meta: { title: "订单管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/order/providedList",
        name: "平台分销订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "平台分销订单", requireAuth: true },
        component: () => import('@/views/order/providedList.vue')
      },
      {
        path: "/order/merchantOrder",
        name: "商户分销订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "商户分销订单", requireAuth: true },
        component: () => import('@/views/order/merchantOrder.vue')
      },
      {
        path: "/order/storeList",
        name: "门店自营订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "门店自营订单", requireAuth: true },
        component: () => import('@/views/order/storeList.vue')
      },
      {
        path: "/order/details/providedOrderDetails",
        name: "平台分销订单详情",
        icon: "icon-shouye",
        meta: { title: "平台分销订单详情", requireAuth: true },
        component: () => import('@/views/order/details/providedOrderDetails.vue')
      },
      {
        path: "/order/details/storeOrderDetails",
        name: "门店自营订单详情",
        icon: "icon-shouye",
        meta: { title: "门店自营订单详情", requireAuth: true },
        component: () => import('@/views/order/details/storeOrderDetails.vue')
      },
      {
        path: "/order/details/merchantOrderDetails",
        name: "商户分销订单详情",
        icon: "icon-shouye",
        meta: { title: "商户分销订单详情", requireAuth: true },
        component: () => import('@/views/order/details/merchantOrderDetails.vue')
      }
    ]
  },
  {
    path: '/after',
    name: '售后管理',
    icon: "icon-shezhi",
    meta: { title: "售后管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/after/afterProvidedOrder",
        name: "平台分销售后",
        icon: "icon-wj-shd",
        meta: { title: "平台分销售后", requireAuth: true },
        component: () => import('@/views/after/afterProvidedOrder.vue')
      },
      {
        path: "/after/afterMerchantOrder",
        name: "商户分销售后",
        icon: "icon-wj-shd",
        meta: { title: "商户分销售后", requireAuth: true },
        component: () => import('@/views/after/afterMerchantOrder.vue')
      },
      {
        path: "/after/afterStoreOrder",
        name: "门店自营售后",
        icon: "icon-wj-shd",
        meta: { title: "门店自营售后", requireAuth: true },
        component: () => import('@/views/after/afterStoreOrder.vue')
      },
      {
        path: "/after/details/providedAfterDetails",
        name: "平台分销售后详情",
        icon: "icon-shouye",
        meta: { title: "平台分销售后详情", requireAuth: true },
        component: () => import('@/views/after/details/providedAfterDetails.vue')
      },
      {
        path: "/after/details/merchantAfterDetails",
        name: "商户分销售后详情",
        icon: "icon-shouye",
        meta: { title: "商户分销售后详情", requireAuth: true },
        component: () => import('@/views/after/details/merchantAfterDetails.vue')
      },
      {
        path: "/after/details/storeAfterDetails",
        name: "门店自营售后详情",
        icon: "icon-shouye",
        meta: { title: "门店自营售后详情", requireAuth: true },
        component: () => import('@/views/after/details/storeAfterDetails.vue')
      }
    ]
  },
  {
    path: '/replenish',
    name: '补货通知',
    icon: "icon-shouye",
    meta: { title: "补货通知", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/replenish",
        // name: "补货通知",
        icon: "icon-shouye",
        meta: { title: "补货通知", requireAuth: true },
        component: () => import('@/views/replenish/index.vue')
      }
    ]
  },
  {
    path: '/moneyManagement',
    name: '币类管理',
    icon: "icon-shouye",
    meta: { title: "币类管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/moneyManagement/amountSetting",
        name: "金额设置",
        icon: "icon-zhuti_o",
        meta: { title: "金额设置", requireAuth: true },
        component: () => import('@/views/moneyManagement/amountSetting.vue')
      },{
        path: "/moneyManagement/chargeList",
        name: "充值记录",
        icon: "icon-zhuti_o",
        meta: { title: "充值记录", requireAuth: true },
        component: () => import('@/views/moneyManagement/chargeList.vue')
      },{
        path: "/moneyManagement/withdrawalList",
        name: "余额提现",
        icon: "icon-zhuti_o",
        meta: { title: "余额提现", requireAuth: true },
        component: () => import('@/views/moneyManagement/withdrawalList.vue')
      },{
        path: "/moneyManagement/buyMoneyList",
        name: "采购金",
        icon: "icon-zhuti_o",
        meta: { title: "采购金", requireAuth: true },
        component: () => import('@/views/moneyManagement/buyMoneyList.vue')
      },
    ]
  },
  {
    path: '/address',
    name: '物流管理',
    icon: "icon-shezhi",
    meta: { title: "物流管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/address/addressList",
        name: "地址设置",
        icon: "icon-zhuti_o",
        meta: { title: "地址设置", requireAuth: true },
        component: () => import('@/views/address/addressList.vue')
      },
      {
        path: "/address/details/addressDetails",
        name: "地址设置详情",
        icon: "icon-zhuti_o",
        meta: { title: "地址设置详情", requireAuth: true },
        component: () => import('@/views/address/details/addressDetails.vue')
      },
      {
        path: "/address/freightTemplate",
        name: "运费模版",
        icon: "icon-zhuti_o",
        meta: { title: "运费模版", requireAuth: true },
        component: () => import('@/views/address/freightTemplate.vue')
      },
      {
        path: "/address/details/freightDetails",
        name: "运费模板详情",
        icon: "icon-zhuti_o",
        meta: { title: "运费模板详情", requireAuth: true },
        component: () => import('@/views/address/details/freightDetails.vue')
      },
    ]
  },
  {
    path: '/finance',
    name: '财务管理',
    icon: "icon-shezhi",
    meta: { title: "财务管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/finance/paymentDetail",
        name: "货款明细", 
        icon: "icon-changyongxinxi",
        meta: { title: "货款明细", requireAuth: true },
        component: () => import('@/views/finance/paymentDetail.vue')
      },
      {
        path: "/finance/paymentStatement",
        name: "货款明细对账单", 
        icon: "icon-changyongxinxi",
        meta: { title: "货款明细对账单", requireAuth: true },
        component: () => import('@/views/finance/paymentStatement.vue')
      },
      {
        path: "/finance/paymentManagement",
        name: "货款管理", 
        icon: "icon-changyongxinxi",
        meta: { title: "货款管理", requireAuth: true },
        component: () => import('@/views/finance/paymentManagement.vue')
      },
      {
        path: "/finance/paymentDistributionList",
        name: "分销列表", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销列表", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionList.vue')
      },
      {
        path: "/finance/paymentDistributionDetails",
        name: "分销款明细", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销款明细", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionDetails.vue')
      },
      {
        path: "/finance/paymentDistributionStatement",
        name: "分销款明细对账单", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销款明细对账单", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionStatement.vue')
      },
      {
        path: "/finance/paymentDistributionManagement",
        name: "分销款管理", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销款管理", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionManagement.vue')
      }
    ]
  },
  {
    path: '/setup',
    name: '设置',
    icon: "icon-shezhi",
    meta: { title: "设置", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/setup/merchantInfoSetting",
        name: "商户信息设置",
        icon: "icon-changyongxinxi",
        meta: { title: "商户信息设置", requireAuth: true },
        component: () => import('@/views/setup/merchantInfoSetting.vue')
      },
      {
        path: "/setup/account",
        name: "提现账号",
        icon: "icon-changyongxinxi",
        meta: { title: "提现账号", requireAuth: true },
        component: () => import('@/views/setup/account.vue')
      },
      {
        path: "/setup/editMerchantInfo",
        name: "编辑商户信息",
        icon: "icon-changyongxinxi",
        meta: { title: "编辑商户信息", requireAuth: true },
        component: () => import('@/views/setup/editMerchantInfo/editMerchantInfo.vue')
      },
      {
        path: "/setup/merchantOrderSettings",
        name: "商户订单设置",
        icon: "icon-changyongxinxi",
        meta: { title: "商户订单设置", requireAuth: true },
        component: () => import('@/views/setup/merchantOrderSettings.vue')
      },
      // {
      //   path: "/setup/merchantAfterSalesSettings",
      //   name: "商户售后订单设置",
      //   icon: "icon-changyongxinxi",
      //   meta: { title: "商户售后订单设置", requireAuth: true },
      //   component: () => import('@/views/setup/merchantAfterSalesSettings.vue')
      // },
      {
        path: "/setup/auditSettings",
        name: "审核设置",
        icon: "icon-changyongxinxi",
        meta: { title: "审核设置", requireAuth: true },
        component: () => import('@/views/setup/auditSettings.vue')
      },
      {
        path: "/setup/shopGoodsSet",
        name: "铺货设置",
        icon: "icon-changyongxinxi",
        meta: { title: "铺货设置", requireAuth: true },
        component: () => import('@/views/setup/shopGoodsSet.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/404.vue')
  }
]

//添加以下代码--解决路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title;
  document.title = localStorage.getItem('merchant_logo_title') || '商户管理系统';//'众康联美康商户管理系统';
  let hasToken = localStorage.getItem('hasToken');
  if(hasToken){
    next();
  }else{
    if(to.path == '/login'){
      return next();
    }else{
      next({path:'/'});
    }
  }
})

export default router
